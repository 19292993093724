import process from 'process';
import { ModeEnvs } from '@app/constants/chains';
import { Base, Mode, ModeTestnet } from '@thirdweb-dev/chains';
import { modeMainnetConfig } from '@app/config/modeMainnetConfig';
import { modeTestnetConfig } from '@app/config/modeTestnetConfig';
import { baseMainnetConfig } from '@app/config/baseMainnetConfig';
import { ApplicationConfig } from '@app/config/types';
import { useChainId } from '@thirdweb-dev/react';

export const envConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  useBase: process.env.NEXT_PUBLIC_USE_BASE === 'true'
};

const APP_CONFIG: Record<number, ApplicationConfig> = {
  [Mode.chainId]: modeMainnetConfig,
  [ModeTestnet.chainId]: modeTestnetConfig,
  [Base.chainId]: baseMainnetConfig
};

export function getConfig(
  chainId: number | undefined
): ApplicationConfig | undefined {
  if (!chainId) {
    return;
  }

  return APP_CONFIG[chainId] ?? APP_CONFIG[Mode.chainId];
}

export function useConfig(_chainId?: number) {
  const chainId = useChainId();

  return getConfig(_chainId ?? chainId);
}
