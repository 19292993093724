import { ModeEnvs } from '@app/constants/chains';
import { ApplicationConfig } from '@app/config/types';
import { Token } from '@app/types/token';

export const baseMainnetConfig: ApplicationConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ?? '',
  CONTENT: {
    tokensWhitelist: process.env.NEXT_PUBLIC_BASE_WHITELIST_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_BASE_WHITELIST_TOKENS
        ) as unknown as Token[])
      : [],
    useWhiteListTokens: true,
    publicSaleLink: '',
    poolsOrder: [],
    hiddenPools: [],
    defaultTokens: {
      from: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      to: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913'
    },
    nftMarketplaceAllowedTokens: process.env
      .NEXT_PUBLIC_MARKETPLACE_ALLOWED_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_MARKETPLACE_ALLOWED_TOKENS
        ) as unknown as Token[])
      : []
  },
  ALGEBRA_ADDRESSES: {
    POOL_INIT_CODE_HASH:
      '0xf96d2474815c32e070cd63233f06af5413efc5dcb430aee4ff18cc29007c562d',
    ALGEBRA_FACTORY: '0x2F0d41f94d5D1550b79A83D2fe85C82d68c5a3ca',
    ALGEBRA_POOL_DEPLOYER: '0x872f5746B3D8CC46A876cBa2269813733A56eB1D',
    ALGEBRA_POSITION_MANAGER: '0xB7cF725E5b697F8338B929e6A52df823F2120a6A',
    ALGEBRA_QUOTER: '0x59844C00227fD2A65db8fccD55b0e242d6A93777',
    ALGEBRA_QUOTER_V2: '0x901F8036d8d3edf19ef1CF69B05bC4dA41C7aa8f',
    ALGEBRA_ROUTER: '0x44447789719A1fAE137b385fB7326Cdcd3f49c49'
  },
  METROM: {
    DISTRIBUTOR: '0xD1D3Cf05Ef211C71056f0aF1a7FD1DF989E109c3'
  },
  MERKL: {
    DISTRIBUTOR: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae'
  },
  AIRDROP_CONTRACTS: {},
  AIRDROP_TOKENS: {},
  STEER: {
    PROXY_HELPER: '0xcCc5B0b0DF17e3664b22c624659a65b40731622d'
  },
  GAMMA: {
    PROXY_HELPER: '0x45441C3e597f2455Ea6e12fdB66d0CFA547F10d3',
    UNI_PROXY: '0x7FcA81cA9b26500789A755FB8C91644202648166'
  },
  ICHI: {
    PROXY_HELPER: '0x37805a268c73c7d2b0a0e42da7d26c087e1eadb5'
  },
  CONTRACTS: {
    KIM_MASTER: '0x266F6b9EdA0fc2262e84554B13Ff58146490483E',
    FACTORY: '0x14658340D7D1c112b62509bbF449be1897e8dE01',
    ROUTER: '0xC479f0C3E98a8Aff27F43615e24824fB8f5b98C4',
    WETH: '0x4200000000000000000000000000000000000006',
    KIM: '0x5dc25aa049837b696d1dc0f966ac8df1491f819b',
    X_KIM: '0x7B8C5d97c25B65f89817E8046851A32e963fc9cD',
    DIVIDENDS: '0x61A395f822eD86660EB37adCE4bFcd4748945AA8',
    NFT_POOL_FACTORY: '0x0151cb451b7D350e053a3C245736356a1DBc1Af6',
    YIELD_BOOSTER: '',
    NITRO_POOL_FACTORY: '0x9D8b1efB49d0a1de45BAd4eD6539FCFbf98A7cCA',
    NFT_MARKETPLACE: ''
  },
  FLAGS: {
    showPointsBadge: false,
    showLeaderboardPage: false,
    showReferralsPage: false,
    showRyoPages: false,
    showInfoPage: false,
    showNitroPage: true,
    showBoosterPage: false,
    showRewardsPage: false,
    showPartnersPage: false,
    showStake: false,
    showSpNft: true,
    showAnnouncement: false,
    showAirdropClaim: false,
    showBadges: [],
    showVaults: true,
    showRouterIntentPage: true,
    showDosirakPage: false,
    showNftMarketplace: false,
    showMagicAddress: false
  },
  URLS: {
    ichiAggregatorUrl: 'https://api.kim.exchange/api/v1/base-ichi',
    ichiSubgraphUrl:
      'https://api.studio.thegraph.com/query/88584/base-v1-kim/version/latest',
    subgraphUrl:
      'https://api.goldsky.com/api/public/project_clwz69zvvp7ix01w76khbha61/subgraphs/kim-amm/0.0.1/gn',
    middlewareUrl: '',
    explorerApiUrl: '',
    subgraphUrlV3:
      'https://api.goldsky.com/api/public/project_clwz69zvvp7ix01w76khbha61/subgraphs/analytics/1.0.0/gn',
    subgraphUrlSteer:
      'https://api.goldsky.com/api/public/project_clohj3ta78ok12nzs5m8yag0b/subgraphs/steer-protocol-mode/1.1.1/gn',
    middlewareUrlV3: 'https://backend.kim.exchange/api',
    merklApiUrl: 'https://api.merkl.xyz/v3',
    metromApiUrl: '',
    steerApiUrl: 'https://api.steer.finance',
    subgraphUrlNitroPools:
      'https://api.goldsky.com/api/public/project_clwz69zvvp7ix01w76khbha61/subgraphs/nitro-pools/0.0.1/gn',
    nftPoolsSubgraph:
      'https://api.goldsky.com/api/public/project_clwz69zvvp7ix01w76khbha61/subgraphs/nft-pools/0.0.1/gn',
    subgraphUrlNftMarketplace: '',
    gammaApiUrl: 'https://wire2.gamma.xyz/kim/base'
  },
  NFT_MARKETPLACE: {
    rewardNftsWhitelist: []
  }
};
